//contentpages/hompage.cshtml

var season;

$(document).ready(function () {
    
    //console.log('homepage');

    var $jsMarqueeCta = $('.js-marquee-cta');
    if($jsMarqueeCta.length > 0) {
        var ctaHref = $jsMarqueeCta.attr('href');
        //console.log('js-marquee-cta.length', $jsMarqueeCta.length, ctaHref);
        $('.js-video-container').addClass('clickable');
        $('.js-video-container').on('click', function () {
            //console.log('js-video-container');
            window.location.href = ctaHref;
            tw.Analytics.ga4('event', 'Homepage', 'Hero Image CTA', ctaHref);
        })
    }

    $('.js-marquee__action').on('click', function () {
        var ctaLabel = $(this).find('a').text();
        //console.log('js-marquee__action.ctaLabel:', ctaLabel);
        ga('send', 'event', 'Homepage', 'Hero Image CTA', ctaLabel);
    });

    //entire thumbnail click
    $('body').on('click', '.cr__asset__button', function () {
      // var alt = $(this).find('img').attr('alt');
       //console.log('cr__asset__button:', alt);
       ga('send', 'event', 'Homepage', 'UGC', 'Image Click');
    });

    //just CTA click, but image click will also fire
    $('body').on('click', '.ctaExpanded__ctaInner', function () {
        var alt = $(this).find('span').text();
        //console.log('ctaExpanded__ctaInner:', alt);
        ga('send', 'event', 'Homepage', 'UGC', 'Image CTA - ' + alt);
    });

    //Lightbox CTA click
    $('body').on('click', '.ctaPanel__link', function () {
        var alt = $(this).find('.ctaPanel__label').text();
        //console.log('ctaExpanded__ctaInner:', alt);
        ga('send', 'event', 'Homepage', 'UGC', 'Lightbox Image CTA - ' + alt);
    });

    //lightbox next/prev
    $('body').on('click', '.fullscreen__previousHold', function () {
        ga('send', 'event', 'Homepage', 'UGC', 'Left Arrow');
    });
    $('body').on('click', '.fullscreen__nextHold', function () {
        ga('send', 'event', 'Homepage', 'UGC', 'Right Arrow');
    });

    //load more button
    $('body').on('click', '.cr__gallery .loadMore', function () {
        ga('send', 'event', 'Homepage', 'UGC', 'Load More');
    });

    $('.js-video-control').on('click', function() {
        var video = $('.js-hero-video')[0];
        var icon = $(this).find('svg');
    
        if (video.paused) {
            video.play();
            icon.attr('data-icon', 'pause-circle');
        } else {
            video.pause();
            icon.attr('data-icon', 'play-circle');
        }
    });

    $('.floating-feature__info__btn a').click(function () {
        ga('send', 'event', 'Homepage', 'Section 2 CTA', $(this).attr("href"));
    });

    $('.marquee__play').click(function () {
        var videoProperties = $('#Inspiration_YouTube-Container').data('property');
        ga('send', 'event', 'Homepage', 'Play Video', videoProperties["videoURL"]);
    });

    $('.js-homepage-six-picks a').click(function () {
        ga('send', 'event', 'Homepage', 'Six Picks Click', $(this).attr("href"));
    });

    $('.js-cta-one a').click(function () {
        ga('send', 'event', 'Homepage', 'Section 1 CTA', $(this).attr("href"));
    });

    $('.js-toggle button').on('click', function () {
        setSeasonToggles($(this));
        
        tw.Iterable.trackEvent({
            event: 'seasonalClick', 
            name: season
        });
    });

    if ($('.js-seasonal-slick').length > 0) {
        //console.log('found .js-seasonal-slick INITIALIZE');
        hasSeasonalSlick = true;
        initializeSlickSeasonal();
    }

    

    //link grid modals 

    $('.js-modal-video').modaal({
        type: 'video',
        background: 'rgba(198, 186, 168, 1)',
        after_open: function () {
            var captionText = this.$elem.attr('data-modaal-desc');
            var caption = $('<div class="modaal-gallery-label">' + captionText + '</div>');
            $('.modaal-video-wrap').append(caption);
            
        }
    });

    //initial state of toggles
    season = getCookie("season");
    //console.log('season.getCookie()', season);
    $('#btn' + season).addClass('active');

    if ($('.js-toggle button.active').length > 0) {
        //console.log('found .js-toggle button.active: ', $('.js-toggle button.active').eq(0));
        setSeasonToggles($('.js-toggle button.active').eq(0));
    }

    if ($('.js-load-season').length > 0) {
        $('.js-load-season').each(function () {
            var $items = $(this).find('.js-load-season-item'),
                $button = $(this).find('.js-load-season-button');
            $items.addClass('hidden').eq(0).removeClass('hidden');
            
            $button.click(function () {
                $items.removeClass('hidden');
                $(this).addClass('hidden');
            });
        });
    }

    /** *analytics tracking event */
    $('body').on('click', '.js-analytics-event', function () {
        //console.log('.js-analytics-event.click');
        var category = $(this).data('event-category') != null ? $(this).data('event-category') : '',
            action = $(this).data('event-action') != null ? $(this).data('event-action') : '',
            label = $(this).data('event-label') != null ? $(this).data('event-label') : '';
        tw.Analytics.ga4(category, action, label);
    });

    var resizeTimer; // Set resizeTimer to empty so it resets on page load
    $(window).resize(function () {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
            Wdot.HomepageVideoHelper.anchorHeadline($('.js-anchored-headline'), $('.js-anchor-container'));
            trackResize();
        }, 50);
    });
    trackResize();
     
});



var isMobile = false,
    lastIsMobile = null,
    mobileBreak = 768,
    hasSeasonalSlick = false;
/**
 * Trigger methods on resize
*/
var trackResize = function () {
    //console.log('trackResize()');

    if(window.innerWidth <= mobileBreak){
        isMobile = true;
    } else {
        isMobile = false;
    }
    if(lastIsMobile != isMobile){
        lastIsMobile = isMobile;
        //trigger slick to update or destroy
        if(isMobile) {
            if(hasSeasonalSlick) initializeSlickSeasonal();
        } else {
            if(hasSeasonalSlick) unslickSeasonal();
        }
    } 

}

var initializeSlickSeasonal = function () {
    //console.log('initializeSlickSeasonal()');
    var $slickElement = $('.js-seasonal-slick');
    // $slickElement.on('init', function(event, slick){
    //     console.log("SLICK initialized")
    // });
    $slickElement.slick({
        infinite: false,
        slidesToShow: 1.5,
        slidesToScroll: 1,
        appendArrows: false,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 9000,
                settings: {
                    slidesToShow: 3,//temp  todo- switch this back to more as we add more items
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                }
            }
        ]
    });
}

var unslickSeasonal = function () {
    $('body').find('.js-seasonal-slick.slick-initialized').slick('unslick');
}



function setSeasonToggles(button) {
    //console.log('setSeasonToggles.button:', button);
    season = button.attr('data-season');
    var containers = $('.js-toggle-container');
    var thisContainer = containers.filter('[data-season="' + season + '"]');

    containers.filter(':visible').removeClass('toggle__section--animate');
    $('.js-toggle-copy').addClass('hidden');
    $('.js-toggle button').removeClass('active');
    button.addClass('active');
    $('.js-toggle-copy[data-season="' + season + '"]').removeClass('hidden');

    thisContainer.addClass('toggle__section--animate');
}

function getCookie(cname) {
    //console.log('getCookie: ', cname);
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}